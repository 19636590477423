export interface AppleStock {
    date: string;
    close: number;
}

export const myAppleStock: AppleStock[] = [
    {date: "2022-01-25",close: 159.779999},
    {date: "2022-01-26",close: 159.690002},
    {date: "2022-01-27",close: 159.220001},
    {date: "2022-01-28",close: 170.330002},
    {date: "2022-01-31",close: 174.779999},
    {date: "2022-02-01",close: 174.610001},
    {date: "2022-02-02",close: 175.839996},
    {date: "2022-02-03",close: 172.899994},
    {date: "2022-02-04",close: 172.389999},
    {date: "2022-02-07",close: 171.660004},
    {date: "2022-02-08",close: 174.830002},
    {date: "2022-02-09",close: 176.279999},
    {date: "2022-02-10",close: 172.119995},
    {date: "2022-02-11",close: 168.639999},
    {date: "2022-02-14",close: 168.880005},
    {date: "2022-02-15",close: 172.789993},
    {date: "2022-02-16",close: 172.550003},
    {date: "2022-02-17",close: 168.880005},
    {date: "2022-02-18",close: 167.300003},
    {date: "2022-02-22",close: 164.320007},
    {date: "2022-02-23",close: 160.070007},
    {date: "2022-02-24",close: 162.740005},
    {date: "2022-02-25",close: 164.850006},
    {date: "2022-02-28",close: 165.119995},
    {date: "2022-03-01",close: 163.199997},
    {date: "2022-03-02",close: 166.559998},
    {date: "2022-03-03",close: 166.229996},
    {date: "2022-03-04",close: 163.169998},
    {date: "2022-03-07",close: 159.300003},
    {date: "2022-03-08",close: 157.440002},
    {date: "2022-03-09",close: 162.949997},
    {date: "2022-03-10",close: 158.520004},
    {date: "2022-03-11",close: 154.729996},
    {date: "2022-03-14",close: 150.619995},
    {date: "2022-03-15",close: 155.089996},
    {date: "2022-03-16",close: 159.589996},
    {date: "2022-03-17",close: 160.619995},
    {date: "2022-03-18",close: 163.979996},
    {date: "2022-03-21",close: 165.380005},
    {date: "2022-03-22",close: 168.820007},
    {date: "2022-03-23",close: 170.210007},
    {date: "2022-03-24",close: 174.070007},
    {date: "2022-03-25",close: 174.720001},
    {date: "2022-03-28",close: 175.600006},
    {date: "2022-03-29",close: 178.960007},
    {date: "2022-03-30",close: 177.770004},
    {date: "2022-03-31",close: 174.610001},
    {date: "2022-04-01",close: 174.309998},
    {date: "2022-04-04",close: 178.440002},
    {date: "2022-04-05",close: 175.059998},
    {date: "2022-04-06",close: 171.830002},
    {date: "2022-04-07",close: 172.139999},
    {date: "2022-04-08",close: 170.089996},
    {date: "2022-04-11",close: 165.750000},
    {date: "2022-04-12",close: 167.660004},
    {date: "2022-04-13",close: 170.399994},
    {date: "2022-04-14",close: 165.289993},
    {date: "2022-04-18",close: 165.070007},
    {date: "2022-04-19",close: 167.399994},
    {date: "2022-04-20",close: 167.229996},
    {date: "2022-04-21",close: 166.419998},
    {date: "2022-04-22",close: 161.789993},
    {date: "2022-04-25",close: 162.880005},
    {date: "2022-04-26",close: 156.800003},
    {date: "2022-04-27",close: 156.570007},
    {date: "2022-04-28",close: 163.639999},
    {date: "2022-04-29",close: 157.649994},
    {date: "2022-05-02",close: 157.960007},
    {date: "2022-05-03",close: 159.479996},
    {date: "2022-05-04",close: 166.020004},
    {date: "2022-05-05",close: 156.770004},
    {date: "2022-05-06",close: 157.279999},
    {date: "2022-05-09",close: 152.059998},
    {date: "2022-05-10",close: 154.509995},
    {date: "2022-05-11",close: 146.500000},
    {date: "2022-05-12",close: 142.559998},
    {date: "2022-05-13",close: 147.110001},
    {date: "2022-05-16",close: 145.539993},
    {date: "2022-05-17",close: 149.240005},
    {date: "2022-05-18",close: 140.820007},
    {date: "2022-05-19",close: 137.350006},
    {date: "2022-05-20",close: 137.589996},
    {date: "2022-05-23",close: 143.110001},
    {date: "2022-05-24",close: 140.360001},
    {date: "2022-05-25",close: 140.520004},
    {date: "2022-05-26",close: 143.779999},
    {date: "2022-05-27",close: 149.639999},
    {date: "2022-05-31",close: 148.839996},
    {date: "2022-06-01",close: 148.710007},
    {date: "2022-06-02",close: 151.210007},
    {date: "2022-06-03",close: 145.380005},
    {date: "2022-06-06",close: 146.139999},
    {date: "2022-06-07",close: 148.710007},
    {date: "2022-06-08",close: 147.960007},
    {date: "2022-06-09",close: 142.639999},
    {date: "2022-06-10",close: 137.130005},
    {date: "2022-06-13",close: 131.880005},
    {date: "2022-06-14",close: 132.759995},
    {date: "2022-06-15",close: 135.429993},
    {date: "2022-06-16",close: 130.059998},
    {date: "2022-06-17",close: 131.559998},
    {date: "2022-06-21",close: 135.869995},
    {date: "2022-06-22",close: 135.350006},
    {date: "2022-06-23",close: 138.270004},
    {date: "2022-06-24",close: 141.660004},
    {date: "2022-06-27",close: 141.660004},
    {date: "2022-06-28",close: 137.440002},
    {date: "2022-06-29",close: 139.229996},
    {date: "2022-06-30",close: 136.720001},
    {date: "2022-07-01",close: 138.929993},
    {date: "2022-07-05",close: 141.559998},
    {date: "2022-07-06",close: 142.919998},
    {date: "2022-07-07",close: 146.350006},
    {date: "2022-07-08",close: 147.039993},
    {date: "2022-07-11",close: 144.869995},
    {date: "2022-07-12",close: 145.860001},
    {date: "2022-07-13",close: 145.490005},
    {date: "2022-07-14",close: 148.470001},
    {date: "2022-07-15",close: 150.169998},
    {date: "2022-07-18",close: 147.070007},
    {date: "2022-07-19",close: 151.000000},
    {date: "2022-07-20",close: 153.039993},
    {date: "2022-07-21",close: 155.350006},
    {date: "2022-07-22",close: 154.089996},
    {date: "2022-07-25",close: 152.949997},
    {date: "2022-07-26",close: 151.600006},
    {date: "2022-07-27",close: 156.789993},
    {date: "2022-07-28",close: 157.350006},
    {date: "2022-07-29",close: 162.509995},
    {date: "2022-08-01",close: 161.509995},
    {date: "2022-08-02",close: 160.009995},
    {date: "2022-08-03",close: 166.130005},
    {date: "2022-08-04",close: 165.809998},
    {date: "2022-08-05",close: 165.350006},
    {date: "2022-08-08",close: 164.869995},
    {date: "2022-08-09",close: 164.919998},
    {date: "2022-08-10",close: 169.240005},
    {date: "2022-08-11",close: 168.490005},
    {date: "2022-08-12",close: 172.100006},
    {date: "2022-08-15",close: 173.190002},
    {date: "2022-08-16",close: 173.029999},
    {date: "2022-08-17",close: 174.550003},
    {date: "2022-08-18",close: 174.149994},
    {date: "2022-08-19",close: 171.520004},
    {date: "2022-08-22",close: 167.570007},
    {date: "2022-08-23",close: 167.229996},
    {date: "2022-08-24",close: 167.529999},
    {date: "2022-08-25",close: 170.029999},
    {date: "2022-08-26",close: 163.619995},
    {date: "2022-08-29",close: 161.380005},
    {date: "2022-08-30",close: 158.910004},
    {date: "2022-08-31",close: 157.220001},
    {date: "2022-09-01",close: 157.960007},
    {date: "2022-09-02",close: 155.809998},
    {date: "2022-09-06",close: 154.529999},
    {date: "2022-09-07",close: 155.960007},
    {date: "2022-09-08",close: 154.460007},
    {date: "2022-09-09",close: 157.369995},
    {date: "2022-09-12",close: 163.429993},
    {date: "2022-09-13",close: 153.839996},
    {date: "2022-09-14",close: 155.309998},
    {date: "2022-09-15",close: 152.369995},
    {date: "2022-09-16",close: 150.699997},
    {date: "2022-09-19",close: 154.479996},
    {date: "2022-09-20",close: 156.899994},
    {date: "2022-09-21",close: 153.720001},
    {date: "2022-09-22",close: 152.740005},
    {date: "2022-09-23",close: 150.429993},
    {date: "2022-09-26",close: 150.770004},
    {date: "2022-09-27",close: 151.759995},
    {date: "2022-09-28",close: 149.839996},
    {date: "2022-09-29",close: 142.479996},
    {date: "2022-09-30",close: 138.199997},
    {date: "2022-10-03",close: 142.449997},
    {date: "2022-10-04",close: 146.100006},
    {date: "2022-10-05",close: 146.399994},
    {date: "2022-10-06",close: 145.429993},
    {date: "2022-10-07",close: 140.089996},
    {date: "2022-10-10",close: 140.419998},
    {date: "2022-10-11",close: 138.979996},
    {date: "2022-10-12",close: 138.339996},
    {date: "2022-10-13",close: 142.990005},
    {date: "2022-10-14",close: 138.380005},
    {date: "2022-10-17",close: 142.410004},
    {date: "2022-10-18",close: 143.750000},
    {date: "2022-10-19",close: 143.860001},
    {date: "2022-10-20",close: 143.389999},
    {date: "2022-10-21",close: 147.270004},
    {date: "2022-10-24",close: 149.449997},
    {date: "2022-10-25",close: 152.339996},
    {date: "2022-10-26",close: 149.350006},
    {date: "2022-10-27",close: 144.800003},
    {date: "2022-10-28",close: 155.740005},
    {date: "2022-10-31",close: 153.339996},
    {date: "2022-11-01",close: 150.649994},
    {date: "2022-11-02",close: 145.029999},
    {date: "2022-11-03",close: 138.880005},
    {date: "2022-11-04",close: 138.380005},
    {date: "2022-11-07",close: 138.919998},
    {date: "2022-11-08",close: 139.500000},
    {date: "2022-11-09",close: 134.869995},
    {date: "2022-11-10",close: 146.869995},
    {date: "2022-11-11",close: 149.699997},
    {date: "2022-11-14",close: 148.279999},
    {date: "2022-11-15",close: 150.039993},
    {date: "2022-11-16",close: 148.789993},
    {date: "2022-11-17",close: 150.720001},
    {date: "2022-11-18",close: 151.289993},
    {date: "2022-11-21",close: 148.009995},
    {date: "2022-11-22",close: 150.179993},
    {date: "2022-11-23",close: 151.070007},
    {date: "2022-11-25",close: 148.110001},
    {date: "2022-11-28",close: 144.220001},
    {date: "2022-11-29",close: 141.169998},
    {date: "2022-11-30",close: 148.029999},
    {date: "2022-12-01",close: 148.309998},
    {date: "2022-12-02",close: 147.809998},
    {date: "2022-12-05",close: 146.630005},
    {date: "2022-12-06",close: 142.910004},
    {date: "2022-12-07",close: 140.940002},
    {date: "2022-12-08",close: 142.649994},
    {date: "2022-12-09",close: 142.160004},
    {date: "2022-12-12",close: 144.490005},
    {date: "2022-12-13",close: 145.470001},
    {date: "2022-12-14",close: 143.210007},
    {date: "2022-12-15",close: 136.500000},
    {date: "2022-12-16",close: 134.509995},
    {date: "2022-12-19",close: 132.369995},
    {date: "2022-12-20",close: 132.300003},
    {date: "2022-12-21",close: 135.449997},
    {date: "2022-12-22",close: 132.229996},
    {date: "2022-12-23",close: 131.860001},
    {date: "2022-12-27",close: 130.029999},
    {date: "2022-12-28",close: 126.040001},
    {date: "2022-12-29",close: 129.610001},
    {date: "2022-12-30",close: 129.929993},
    {date: "2023-01-03",close: 125.070000},
    {date: "2023-01-04",close: 126.360001},
    {date: "2023-01-05",close: 125.019997},
    {date: "2023-01-06",close: 129.619995},
    {date: "2023-01-09",close: 130.149994},
    {date: "2023-01-10",close: 130.729996},
    {date: "2023-01-11",close: 133.490005},
    {date: "2023-01-12",close: 133.410004},
    {date: "2023-01-13",close: 134.759995},
    {date: "2023-01-17",close: 135.940002},
    {date: "2023-01-18",close: 135.210007},
    {date: "2023-01-19",close: 135.270004},
    {date: "2023-01-20",close: 137.869995},
    {date: "2023-01-23",close: 141.110001},
    {date: "2023-01-24",close: 142.529999}
]