import {myAppleStock} from "../data/AAPL";
import {myMetaStock} from "../data/META";
import {MockData, MockDataString} from "../components/interfaces/MockData";
import {myLetterFrequency} from "../data/LETTERFREQ";
import {myTemperature} from "../data/TMPVIJUL";
import {myDefaultData} from "../data/DefaultData";

export const getX = (data: MockData) => data?.label;
export const getY = (data: MockData) => data?.value;

export const getAppleStock = (size?: number): MockData[] => {
    return myAppleStock.slice(0, size).map((d, i) => {
        return {label: new Date(d.date), value: Math.floor(Number(d.close)), category: "AppleStock in USD", unit: "USD"}
    })
}

export const getMetaStock = (size?: number): MockData[] => {
    return myMetaStock.slice(0, size).map((d, i) => {
        return {label: new Date(d.date), value: Math.floor(Number(d.close)), category: "MetaStock in USD", unit: "USD"}
    })
}

export const getTemperature = (size?: number): MockData[] => {
    return myTemperature.slice(0, size).map((d, i) => {
        return {label: new Date(d.date), value: Math.floor(Number(d.temp)), category: "Temperature in Celsius", unit: "Celsius"}
    })
}

export const getDefaultData = (size?: number): MockData[] => {
    return myDefaultData.slice(0, size).map((d, i) => {
        return {label: new Date(d.date), value: Math.floor(Number(d.data)), category: "TestData", unit: "1"}
    })
}
