import moment from "moment";
import React, {RefObject, useEffect, useRef} from "react";
import styled from "styled-components";
import {getX} from "../../utils/GetData";
import {AxisBottom, AxisLeft, AxisScale} from "@visx/axis";
import {Group} from "@visx/group";
import {ScaleBand} from "d3-scale";
import {Brush} from "@visx/brush";
import BaseBrush, {UpdateBrush} from "@visx/brush/lib/BaseBrush";
import {Text} from "@visx/text";
import {IndexFilter} from "../interfaces/IndexFilter";
import {Margin} from "../interfaces/Margin";
import {LegendEntry} from "../interfaces/LegendEntry";
import {MockData} from "../interfaces/MockData";
import {updateFilterAPI} from "../../utils/APICalls";

const SvgContainer = styled("svg")`
  pointer-events: none;
`

interface AccessGroupProps {
    data: MockData[],
    width: number,
    height: number,
    margin: Margin
    xScale: ScaleBand<any>,
    yScale: AxisScale<number>,
    filter?: IndexFilter
    focusableRefs?: RefObject<any>[]
    legendEntries?: LegendEntry[]
    children?: React.ReactNode
}


export const AccessGroup = (props: AccessGroupProps) => {
    const innerHeight = props.height - props.margin.top - props.margin.bottom;
    const innerWidth = props.width - props.margin.left - props.margin.right;
    const containerRef = useRef<SVGSVGElement>(null)
    const brushRef = useRef<BaseBrush>(null);

    useEffect(() => {
        if (props.filter?.filterEnd === props.data.length - 1 && props.filter.filterStart === 0) {
            brushRef.current?.reset();
        } else if (props && props.filter && props.data && brushRef?.current) {
            const updater: UpdateBrush = (prevBrush) => {
                const newExtent = brushRef.current!.getExtent(
                    {x: props.xScale(getX(props.data[props.filter!.filterStart!]))},
                    {x: props.xScale(getX(props.data[props.filter!.filterEnd!]))}
                );
                return {
                    ...prevBrush,
                    start: {y: newExtent.y0, x: newExtent.x0},
                    end: {y: newExtent.y1, x: newExtent.x1},
                    extent: newExtent,
                }
            }
            brushRef.current.updateBrush(updater);
        }
    }, [props])

    const containerNavigation = (keyboardEvent: React.KeyboardEvent<SVGSVGElement>) => {
        if (props.focusableRefs?.length) {
            switch (keyboardEvent.key) {
                case 'Enter':
                    props.focusableRefs[0].current?.focus()
                    break;
                case 'Escape':
                    containerRef.current?.focus()
                    break;
                default:
                    break;
            }
        }
    }

    return (
        <SvgContainer
            aria-label={
                `Interactive chart of ${props.data[0].category}. ${props.data.length} entries from ${moment(props.data[0].label).format("MMM Do YYYY")} to ${moment(props.data[props.data.length - 1].label).format("MMM Do YYYY")}`}
            aria-roledescription="chart group"
            role={"application"}
            tabIndex={0}
            width={props.width}
            height={props.height}
            ref={containerRef}
            onKeyDown={(e) => containerNavigation(e)}
        >

            <Group
                left={props.margin.left}
                top={props.margin.top}
            >
                {props.children}
                <Brush
                    xScale={props.xScale}
                    yScale={props.yScale}
                    width={innerWidth}
                    height={innerHeight}
                    innerRef={brushRef}
                    brushDirection="horizontal"
                />

                <AxisBottom
                    top={innerHeight}
                    scale={props.xScale}
                    numTicks={props.width / 20}
                    tickFormat={value => moment(value).format("MMM Do YYYY")}
                    tickLabelProps={(value) => {
                        return {
                            textAnchor: 'start',
                            fontSize: 10,
                            transform: `rotate(45 ${props.xScale(value)} 10)`,
                        }
                    }}
                />
                <AxisLeft
                    scale={props.yScale}
                />
                {props.legendEntries?.map((le, i) => {
                    return (
                        <Text
                            key={le.label + i}
                            y={i * 20}
                            x={innerWidth}
                            fill={le.color}
                            style={{fontWeight: 900}}
                            width={innerWidth}
                            verticalAnchor="start"
                            textAnchor="end">
                            {le.label + " ■"}
                        </Text>
                    )
                })}
            </Group>
        </SvgContainer>
    )
}