import styled from "styled-components";
import {getAppleStock, getMetaStock, getTemperature, getX, getY} from "../../utils/GetData";
import {AccessGroup} from "../accessGroup/AccessGroup";
import {ScaleBand, ScaleLinear} from "d3-scale";
import {scaleBand, scaleLinear} from "@visx/scale";
import {DatePickerContainer} from "../../utils/DatePickerContainer";
import React, {RefObject, useEffect, useRef, useState} from "react";
import {VisxBarGraph} from "../bargraph/VisxBarGraph";
import {AudioLineGraph} from "../audioLineGraph/AudioLineGraph";
import {SonificationPlayer} from "../../audio/AudioPlayer";
import QRCode from "react-qr-code";
import {Margin} from "../interfaces/Margin";
import {IndexFilter} from "../interfaces/IndexFilter";
import {getFilterAPI, updateFilterAPI} from "../../utils/APICalls";

const Container = styled("div")`
  padding: 10px;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #F0F0F0;
`

const FormButton = styled("button")`
  min-width: 120px;
`

const QRCodeContainer = styled("div")`
  background: white;
  padding: 16px;
  width: 150px;
  height: 150px;
`

const metaData = getMetaStock(50)
const APICALL_TIME = 2000 // check Filter every two seconds
export const DashBoardTwo = () => {
    const margin: Margin = {top: 30, bottom: 100, left: 40, right: 40};
    const width = 1000
    const height = 400
    const dataSmoothWindow = 5
    const metaColor1 = "#0668E1"
    const metaColor2 = "#0080FB"
    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom

    const audioBarGraphRef = useRef<SonificationPlayer>(null)
    const audioLineGraphRef = useRef<SonificationPlayer>(null)
    const barGraphRef = useRef<SVGRectElement>(null)
    const lineGraphRef = useRef<SVGRectElement>(null)
    const [filterStartDate, setFilterStartDate] = useState(metaData[0].label);
    const [filterEndDate, setFilterEndDate] = useState<Date>(metaData[metaData.length - 1].label);
    const [filterIndex, setFilterIndex] = useState<IndexFilter | undefined>(undefined)
    const [audioIndex, setAudioIndex] = useState<number>(0)
    const [audioSpeedMultiplier, setAudioSpeedMultiplier] = useState<number>(1)
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(() => {
        const getIndexOfDate = (date: Date): number => {
            const index = metaData.map(d => d?.label.toDateString()).indexOf(date?.toDateString())
            if (index < 0) return 0
            if (index > metaData.length - 1) return metaData.length - 1
            return index
        }
        const filterStart = getIndexOfDate(filterStartDate)
        const filterEnd = getIndexOfDate(filterEndDate)
        setFilterIndex({filterStart, filterEnd})
        updateFilterAPI(filterStart,filterEnd).then(() => setIsReady(true));
    }, [filterStartDate, filterEndDate])

    useEffect(() => {
        let failedAPICalls = 0;
        const interval = setInterval(() => {
            if (isReady) {
                getFilterAPI().then((res) => {
                    if (res) {
                        if (res.filterStart !== filterIndex?.filterStart || res.filterEnd !== res.filterEnd) {
                            console.log("Setting...")
                            setFilterIndex(res)
                        }
                    } else {
                        failedAPICalls++
                    }});
                if (failedAPICalls >= 10) clearInterval(interval)
            }
        }, APICALL_TIME);
        return () => clearInterval(interval);
    }, []);


    const xScale: ScaleBand<Date> = scaleBand({
        range: [0, innerWidth],
        round: false,
        domain: metaData.map(getX),
        padding: 0.4
    });
    const yScale: ScaleLinear<number, number> = scaleLinear({
        range: [innerHeight, 0],
        round: true,
        domain: [0, Math.max(...metaData.map(getY))],
        nice: true
    });

    // able to pause audio
    const controlAudio = (keyboardEvent: React.KeyboardEvent<HTMLDivElement>) => {
        switch (keyboardEvent.key) {
            case 'o':
                keyboardEvent.preventDefault();
                stopAudioAndFilter(audioLineGraphRef)
                stopAudioAndFilter(audioBarGraphRef)
                break;
            case 'p':
                playAudio(audioBarGraphRef)
                break;
            case 'r':
                resetFilter()
                break;
            default:
                break;
        }
    }

    const resetFilter = () => {
        setFilterStartDate(metaData[0].label)
        setFilterEndDate(metaData[metaData.length - 1].label)
    }

    const playAudio = (audioRef: RefObject<SonificationPlayer>) => {
        audioRef.current?.playSonification()
    }

    const stopAudio = (audioRef: RefObject<SonificationPlayer>) => {
        audioRef.current?.stopSonification()
    }

    const stopAudioAndFilter = (audioRef: RefObject<SonificationPlayer>) => {
        stopAudio(audioRef)
        const filterStart = audioIndex - 10 < 0 ? 0 : audioIndex - 10
        const filterEnd = audioIndex + 10 > metaData.length - 1 ? metaData.length - 1 : audioIndex + 10
        setFilterStartDate(metaData[filterStart].label)
        setFilterEndDate(metaData[filterEnd].label)
    }

    return (
        <Container onKeyDown={controlAudio}>
            <div style={{display: "flex"}}>
                <form style={{display: "flex", flexDirection: "column", marginRight: "50px"}}
                      onSubmit={(e) => e.preventDefault()}>
                    <label htmlFor="filterStart"><h1>Filter</h1></label>
                    <FormButton onClick={resetFilter}>Reset Filter</FormButton>
                    <DatePickerContainer minDate={metaData.length ? metaData[0].label : new Date()}
                                         maxDate={metaData.length > 1 ? metaData[metaData.length - 1].label : new Date()}
                                         startDate={filterStartDate} endDate={filterEndDate}
                                         setStartDate={setFilterStartDate} setEndDate={setFilterEndDate}/>
                    <FormButton onClick={() => playAudio(audioBarGraphRef)}>Play sonification</FormButton>
                    {/*<FormButton onClick={() => playAudio(audioLineGraphRef)}>Play sonification MetaStock</FormButton>*/}
                    <input
                        type="range"
                        min={0.25} max={2}
                        value={audioSpeedMultiplier}
                        onChange={(e) => setAudioSpeedMultiplier(Number(e.target.value))}
                        step="0.25"/>
                </form>
                <QRCodeContainer>
                    <QRCode
                        size={256}
                        style={{height: "auto", maxWidth: "100%", width: "100%"}}
                        value={"https://accesswebcharts.projekte.fh-hagenberg.at/#/mobile2"}
                        viewBox={`0 0 256 256`}
                    />
                </QRCodeContainer>
            </div>
            <AccessGroup
                data={metaData}
                width={width}
                height={height}
                margin={margin}
                xScale={xScale}
                yScale={yScale}
                filter={filterIndex}
                legendEntries={[{
                    label: metaData[0].category,
                    color: metaColor2
                }]}
                focusableRefs={[barGraphRef, lineGraphRef]}
            >

                <VisxBarGraph data={metaData}
                              width={innerWidth}
                              height={innerHeight}
                              xScale={xScale}
                              yScale={yScale}
                              color={metaColor1}
                              filter={filterIndex}
                              ref={barGraphRef}
                />
                <AudioLineGraph data={metaData}
                                xScale={xScale}
                                yScale={yScale}
                                savitzkyFilterWindowSize={dataSmoothWindow}
                                speed={audioSpeedMultiplier * 1.5}
                                color={metaColor2}
                                brushFilter={filterIndex}
                                oscType={"sine"}
                                ref={audioBarGraphRef}
                                setAudioIndex={setAudioIndex}
                />
            </AccessGroup>
        </Container>
    )
}