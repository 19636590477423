import savitzkyGolay from "ml-savitzky-golay";
import {MockData} from "../components/interfaces/MockData";

export function savitzkyGolayFilter(data: MockData[], windowSize: number): MockData[] {
    const copyData: MockData[] = JSON.parse(JSON.stringify(data));
    const smoothedData = savitzkyGolay(copyData.map(d => d.value), 5, { pad: "pre", windowSize, derivative: 0 });
    copyData.forEach((d,i) => {
        d.value = Math.floor(smoothedData[i] ?? d.value)
        d.label = new Date(d.label)
    });
    return copyData;
}

