export interface Temparature {
    date: string;
    temp: number;
}

export const myTemperature: Temparature[] = [
    {date: "2022-07-01", temp: 23},
    {date: "2022-07-02", temp: 22},
    {date: "2022-07-03", temp: 21},
    {date: "2022-07-04", temp: 21},
    {date: "2022-07-05", temp: 20},
    {date: "2022-07-06", temp: 19},
    {date: "2022-07-07", temp: 22},
    {date: "2022-07-08", temp: 23},
    {date: "2022-07-09", temp: 23},
    {date: "2022-07-10", temp: 25},
    {date: "2022-07-11", temp: 26},
    {date: "2022-07-12", temp: 27},
    {date: "2022-07-13", temp: 27},
    {date: "2022-07-14", temp: 28},
    {date: "2022-07-15", temp: 30},
    {date: "2022-07-16", temp: 30},
    {date: "2022-07-17", temp: 31},
    {date: "2022-07-18", temp: 30},
    {date: "2022-07-19", temp: 31},
    {date: "2022-07-20", temp: 30},
]