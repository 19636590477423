import {getTemperature, getX, getY} from "../../utils/GetData";
import {MobileTactileLineGraph} from "./MobileTactileLineGraph";
import {ScaleBand, ScaleLinear} from "d3-scale";
import {scaleBand, scaleLinear} from "@visx/scale";
import {WithScreenSizeProps, WithScreenSizeProvidedProps} from "@visx/responsive/lib/enhancers/withScreenSize";
import React, {useState} from "react";
import {Text} from "@visx/text";
import {useGesture} from "@use-gesture/react";
import {IndexFilter} from "../interfaces/IndexFilter";
import {updateFilterAPI} from "../../utils/APICalls";

interface MobileDashBoardProps extends WithScreenSizeProvidedProps, WithScreenSizeProps {
}

export const MobileDashBoardOne = (props: MobileDashBoardProps) => {
    const width = props.screenWidth ? props.screenWidth : 1000;
    const height = props.screenHeight ? props.screenHeight - 5 : 500;
    const temperature = getTemperature()
    const metaColor1 = "#0668E1"
    const dataSmoothWindow = 5
    const legendMargin = 10;

    const [xFilterValues, setXFilterValues] = useState<IndexFilter>({filterStart: 0, filterEnd: 0})
    const [pinching, setPinching] = useState<String>("Nothing")

    const legendEntries = [
        {label: temperature[0].category, color: metaColor1}
    ]
    const xScale: ScaleBand<Date> = scaleBand({
        range: [0, width],
        round: false,
        domain: temperature.map(getX),
        padding: 0.4
    });
    const yScale: ScaleLinear<number, number> = scaleLinear({
        range: [height, 0],
        round: true,
        domain: [0, Math.max(...temperature.map(getY))],
        nice: true
    });

    // gestures
    const pinch = useGesture(
        {
            onPinch: state => {
                const [ox] = state.origin
                const angle = (state.da[1] + 90) / 180 * Math.PI
                const halfLength = state.da[0] / 2
                const dx = halfLength * Math.cos(angle)
                setXFilterValues({filterStart: ox - dx, filterEnd: ox + dx})
                setPinching("...pinching")
            },
            onPinchEnd: state => {
                setPinching("PINCHED ...")
                updateFilterAPI(xFilterValues.filterStart,xFilterValues.filterEnd).then(() => setPinching("FilterSet"));
                setPinching("Finsihed")
            }
        }
    )

    return (
        <svg
            {...pinch()}
            style={{touchAction: "none"}}
            aria-roledescription="chart group"
            role={"graphics-object"}
            width={width}
            height={height}
        >
            <MobileTactileLineGraph
                data={temperature}
                savitzkyFilterWindowSize={dataSmoothWindow}
                xScale={xScale}
                yScale={yScale}
                color={metaColor1}
                oscType={"sine"}
                pointRadius={40}
            />
            {legendEntries?.map((le, i) => {
                return (
                    <Text
                        key={le.label + i}
                        y={i * 20 + legendMargin}
                        x={width - legendMargin}
                        fill={le.color}
                        style={{fontWeight: 900}}
                        width={width}
                        verticalAnchor="start"
                        textAnchor="end">
                        {le.label + " ■" + pinching}
                    </Text>
                )
            })}
        </svg>
    )
}