import {IndexFilter} from "../components/interfaces/IndexFilter";
import axios from 'axios';

const productionURL = "https://accesswebcharts.projekte.fh-hagenberg.at"
export const getFilterAPI = (): Promise<IndexFilter | void> => {
    const getAPI = `${productionURL}/api/filter_get.php`;
    return axios.get(getAPI)
        .then((response) => {
            if (response?.data?.length) {
                console.log(response.data[0]?.start + " " + response.data[0]?.end)
                return {filterStart: Number(response.data[0]?.start), filterEnd: Number(response.data[0]?.end)};
            }
        })
        .catch(err => console.log(err))
}

export const updateFilterAPI = async (start: number, end: number): Promise<void> => {
    console.log("updating ...")
    const updateAPI = `${productionURL}/api/filter_update.php`;
    return await axios.post(updateAPI, {start: start, end: end, id: 1})
}