import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {notes} from "../data/NoteFrequencies";
import {Note} from "../components/interfaces/Note";
import {IndexFilter} from "../components/interfaces/IndexFilter";

export interface SonificationPlayer {
    /** plays the audio on the current player */
    playSonification(): Promise<void>;

    stopSonification(): Promise<void> | undefined;

    /** play one note from the given data*/
    playNoteAt(noteIndex: number): Promise<boolean>
}

interface AudioPlayerProps {
    data: number[]
    brushFilter?: IndexFilter,
    drawPoint?: boolean,
    highlightPoints?: number[],
    speed: number,
    oscType: OscillatorType,
    setAudioIndex?: (index: number) => void
}

// map datapoints from one range to another range
const map_data = (data: number[], newMin: number, newMax: number): number[] => {
    if (!data.length) return data
    let oldMin = data[0];
    let oldMax = data[0];
    for (const d of data) {
        if (d < oldMin) oldMin = d;
        if (d > oldMax) oldMax = d;
    }

    const result = []
    for (const d of data) {
        result.push(newMin + (d - oldMin) / (oldMax - oldMin) * (newMax - newMin))
    }
    return result
}
const initDuration = 20

export const AudioPlayer = forwardRef<SonificationPlayer, AudioPlayerProps>((props, ref) => {
    const audioContextRef = useRef<AudioContext>();
    const [filterStart, filterEnd] = [props.brushFilter?.filterStart ?? 0, props.brushFilter?.filterEnd ?? props.data.length];
    const [noteArray, setNoteArray] = useState<Note[]>([])
    const createAudioContext = async () => {
        const audioContext = new AudioContext();
        audioContextRef.current = audioContext;
        await audioContext.suspend();
    }

    // on destroy close audioContext
    useEffect(() => {
        return () => {
            audioContextRef.current?.close();
        };
    }, []);

    // onInit create a NoteArray
    useMemo(() => {
        // calculate playtime for each note
        const noteDuration = initDuration / (props.data.length * props.speed)
        // map data to pitch
        const pitchArray = map_data(props.data, 0, 1)
        // get gain for each note (lower freq should sound louder)
        const gain = {min: 0.01, max: 0.05}
        let gainArray = map_data(pitchArray, gain.max, gain.min)

        if (props.brushFilter) {
            gainArray = gainArray.map((gain, index) => {
                if (index < filterStart || index > filterEnd) return gain * 0.1
                return gain
            })
        }

        // get pitch to the nearest note and create note array
        const newNoteArray: Note[] = map_data(pitchArray, 0, notes.length - 1).map((val, index) => {
            if (props.highlightPoints?.includes(index)) return { // sound for highlights
                frequency: notes[Math.round(val)],
                duration: 0.5,
                gainVal: gainArray[index],
                oscType: "triangle"
            }
            else return { // sound for normal notes
                frequency: notes[Math.round(val)],
                duration: noteDuration,
                gainVal: gainArray[index],
                oscType: props.oscType
            }
        })
        setNoteArray(newNoteArray)
    }, [filterEnd, filterStart, props.brushFilter, props.data, props.speed, props.highlightPoints, props.oscType]);

    // call play audio from parent
    useImperativeHandle(ref, () => {
        return ({
            async playSonification(): Promise<void> {
                if (audioContextRef.current?.state !== "closed") await audioContextRef.current?.close()
                await createAudioContext()
                await audioContextRef.current?.resume()

                return new Promise<void>(async resolve => {
                    for (const data of noteArray) {
                        const index = noteArray.indexOf(data);
                        playNote(noteArray[index], index)
                    }
                    await audioContextRef.current
                    resolve();
                })
            },
            playNoteAt: async function (noteIndex: number): Promise<boolean> {
                if (!audioContextRef.current) await createAudioContext()
                await audioContextRef.current?.resume()

                return new Promise<boolean>(resolve => {
                    if (props.setAudioIndex) props.setAudioIndex(noteIndex)
                    playNote(noteArray[noteIndex], 0)
                    resolve(true)
                })
            },
            stopSonification(): Promise<void> | undefined {
                if (audioContextRef.current?.state !== "closed") return audioContextRef.current?.close();
            }
        });
    })

    // connect chaining audio https://developer.mozilla.org/en-US/docs/Web/API/AudioNode/connect
    const playNote = (note: Note, index: number): void => {
        const context = audioContextRef.current
        if (!context) return
        const gain = context.createGain()
        gain.gain.value = note.gainVal
        const osc = context.createOscillator()
        osc.type = note.oscType
        osc.frequency.value = note.frequency
        osc.onended = (() => {
            if (props.setAudioIndex)
                props.setAudioIndex(index)
        })

        gain.connect(context.destination)
        osc.connect(gain)
        osc.start(context.currentTime + note.duration * index)
        osc.stop(context.currentTime + note.duration + note.duration * index)
    }

    return <></>
})

