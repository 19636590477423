export interface MetaStock {
    date: string;
    close: number;
}

export const myMetaStock: MetaStock[] = [
    {date: "2022-01-25", close: 300.149994},
    {date: "2022-01-26", close: 294.630005},
    {date: "2022-01-27", close: 294.640015},
    {date: "2022-01-28", close: 301.709991},
    {date: "2022-01-31", close: 313.260010},
    {date: "2022-02-01", close: 319.000000},
    {date: "2022-02-02", close: 323.000000},
    {date: "2022-02-03", close: 237.759995},
    {date: "2022-02-04", close: 237.089996},
    {date: "2022-02-07", close: 224.910004},
    {date: "2022-02-08", close: 220.179993},
    {date: "2022-02-09", close: 232.000000},
    {date: "2022-02-10", close: 228.070007},
    {date: "2022-02-11", close: 219.550003},
    {date: "2022-02-14", close: 217.699997},
    {date: "2022-02-15", close: 221.000000},
    {date: "2022-02-16", close: 216.539993},
    {date: "2022-02-17", close: 207.710007},
    {date: "2022-02-18", close: 206.160004},
    {date: "2022-02-22", close: 202.080002},
    {date: "2022-02-23", close: 198.449997},
    {date: "2022-02-24", close: 207.600006},
    {date: "2022-02-25", close: 210.479996},
    {date: "2022-02-28", close: 211.029999},
    {date: "2022-03-01", close: 203.490005},
    {date: "2022-03-02", close: 208.110001},
    {date: "2022-03-03", close: 202.970001},
    {date: "2022-03-04", close: 200.059998},
    {date: "2022-03-07", close: 187.470001},
    {date: "2022-03-08", close: 190.289993},
    {date: "2022-03-09", close: 198.500000},
    {date: "2022-03-10", close: 195.210007},
    {date: "2022-03-11", close: 187.610001},
    {date: "2022-03-14", close: 186.630005},
    {date: "2022-03-15", close: 192.029999},
    {date: "2022-03-16", close: 203.630005},
    {date: "2022-03-17", close: 207.839996},
    {date: "2022-03-18", close: 216.490005},
    {date: "2022-03-21", close: 211.490005},
    {date: "2022-03-22", close: 216.649994},
    {date: "2022-03-23", close: 213.460007},
    {date: "2022-03-24", close: 219.570007},
    {date: "2022-03-25", close: 221.820007},
    {date: "2022-03-28", close: 223.589996},
    {date: "2022-03-29", close: 229.860001},
    {date: "2022-03-30", close: 227.850006},
    {date: "2022-03-31", close: 222.360001},
    {date: "2022-04-01", close: 224.850006},
    {date: "2022-04-04", close: 233.889999},
    {date: "2022-04-05", close: 231.839996},
    {date: "2022-04-06", close: 223.300003},
    {date: "2022-04-07", close: 222.949997},
    {date: "2022-04-08", close: 222.330002},
    {date: "2022-04-11", close: 216.460007},
    {date: "2022-04-12", close: 214.139999},
    {date: "2022-04-13", close: 214.990005},
    {date: "2022-04-14", close: 210.179993},
    {date: "2022-04-18", close: 210.770004},
    {date: "2022-04-19", close: 217.309998},
    {date: "2022-04-20", close: 200.419998},
    {date: "2022-04-21", close: 188.070007},
    {date: "2022-04-22", close: 184.110001},
    {date: "2022-04-25", close: 186.990005},
    {date: "2022-04-26", close: 180.949997},
    {date: "2022-04-27", close: 174.949997},
    {date: "2022-04-28", close: 205.729996},
    {date: "2022-04-29", close: 200.470001},
    {date: "2022-05-02", close: 211.130005},
    {date: "2022-05-03", close: 212.029999},
    {date: "2022-05-04", close: 223.410004},
    {date: "2022-05-05", close: 208.279999},
    {date: "2022-05-06", close: 203.770004},
    {date: "2022-05-09", close: 196.210007},
    {date: "2022-05-10", close: 197.649994},
    {date: "2022-05-11", close: 188.740005},
    {date: "2022-05-12", close: 191.240005},
    {date: "2022-05-13", close: 198.619995},
    {date: "2022-05-16", close: 200.039993},
    {date: "2022-05-17", close: 202.619995},
    {date: "2022-05-18", close: 192.240005},
    {date: "2022-05-19", close: 191.289993},
    {date: "2022-05-20", close: 193.539993},
    {date: "2022-05-23", close: 196.229996},
    {date: "2022-05-24", close: 181.279999},
    {date: "2022-05-25", close: 183.830002},
    {date: "2022-05-26", close: 191.630005},
    {date: "2022-05-27", close: 195.130005},
    {date: "2022-05-31", close: 193.639999},
    {date: "2022-06-01", close: 188.639999},
    {date: "2022-06-02", close: 198.860001},
    {date: "2022-06-03", close: 190.779999},
    {date: "2022-06-06", close: 194.250000},
    {date: "2022-06-07", close: 195.649994},
    {date: "2022-06-08", close: 196.639999},
    {date: "2022-06-09", close: 184.000000},
    {date: "2022-06-10", close: 175.570007},
    {date: "2022-06-13", close: 164.259995},
    {date: "2022-06-14", close: 163.729996},
    {date: "2022-06-15", close: 169.350006},
    {date: "2022-06-16", close: 160.869995},
    {date: "2022-06-17", close: 163.740005},
    {date: "2022-06-21", close: 157.050003},
    {date: "2022-06-22", close: 155.850006},
    {date: "2022-06-23", close: 158.750000},
    {date: "2022-06-24", close: 170.160004},
    {date: "2022-06-27", close: 169.490005},
    {date: "2022-06-28", close: 160.679993},
    {date: "2022-06-29", close: 163.940002},
    {date: "2022-06-30", close: 161.250000},
    {date: "2022-07-01", close: 160.029999},
    {date: "2022-07-05", close: 168.190002},
    {date: "2022-07-06", close: 169.770004},
    {date: "2022-07-07", close: 172.190002},
    {date: "2022-07-08", close: 170.880005},
    {date: "2022-07-11", close: 162.880005},
    {date: "2022-07-12", close: 163.270004},
    {date: "2022-07-13", close: 163.490005},
    {date: "2022-07-14", close: 158.050003},
    {date: "2022-07-15", close: 164.699997},
    {date: "2022-07-18", close: 167.229996},
    {date: "2022-07-19", close: 175.779999},
    {date: "2022-07-20", close: 183.089996},
    {date: "2022-07-21", close: 183.169998},
    {date: "2022-07-22", close: 169.270004},
    {date: "2022-07-25", close: 166.649994},
    {date: "2022-07-26", close: 159.149994},
    {date: "2022-07-27", close: 169.580002},
    {date: "2022-07-28", close: 160.720001},
    {date: "2022-07-29", close: 159.100006},
    {date: "2022-08-01", close: 159.929993},
    {date: "2022-08-02", close: 160.190002},
    {date: "2022-08-03", close: 168.800003},
    {date: "2022-08-04", close: 170.570007},
    {date: "2022-08-05", close: 167.110001},
    {date: "2022-08-08", close: 170.250000},
    {date: "2022-08-09", close: 168.529999},
    {date: "2022-08-10", close: 178.339996},
    {date: "2022-08-11", close: 177.490005},
    {date: "2022-08-12", close: 180.500000},
    {date: "2022-08-15", close: 180.889999},
    {date: "2022-08-16", close: 179.470001},
    {date: "2022-08-17", close: 174.850006},
    {date: "2022-08-18", close: 174.660004},
    {date: "2022-08-19", close: 167.960007},
    {date: "2022-08-22", close: 163.050003},
    {date: "2022-08-23", close: 161.110001},
    {date: "2022-08-24", close: 163.259995},
    {date: "2022-08-25", close: 168.779999},
    {date: "2022-08-26", close: 161.779999},
    {date: "2022-08-29", close: 159.169998},
    {date: "2022-08-30", close: 157.160004},
    {date: "2022-08-31", close: 162.929993},
    {date: "2022-09-01", close: 165.360001},
    {date: "2022-09-02", close: 160.320007},
    {date: "2022-09-06", close: 158.539993},
    {date: "2022-09-07", close: 160.389999},
    {date: "2022-09-08", close: 162.059998},
    {date: "2022-09-09", close: 169.149994},
    {date: "2022-09-12", close: 168.960007},
    {date: "2022-09-13", close: 153.130005},
    {date: "2022-09-14", close: 151.470001},
    {date: "2022-09-15", close: 149.550003},
    {date: "2022-09-16", close: 146.289993},
    {date: "2022-09-19", close: 148.020004},
    {date: "2022-09-20", close: 146.089996},
    {date: "2022-09-21", close: 142.119995},
    {date: "2022-09-22", close: 142.820007},
    {date: "2022-09-23", close: 140.410004},
    {date: "2022-09-26", close: 136.369995},
    {date: "2022-09-27", close: 134.399994},
    {date: "2022-09-28", close: 141.610001},
    {date: "2022-09-29", close: 136.410004},
    {date: "2022-09-30", close: 135.679993},
    {date: "2022-10-03", close: 138.610001},
    {date: "2022-10-04", close: 140.279999},
    {date: "2022-10-05", close: 138.979996},
    {date: "2022-10-06", close: 139.070007},
    {date: "2022-10-07", close: 133.449997},
    {date: "2022-10-10", close: 133.789993},
    {date: "2022-10-11", close: 128.539993},
    {date: "2022-10-12", close: 127.500000},
    {date: "2022-10-13", close: 130.289993},
    {date: "2022-10-14", close: 126.760002},
    {date: "2022-10-17", close: 134.039993},
    {date: "2022-10-18", close: 132.800003},
    {date: "2022-10-19", close: 133.229996},
    {date: "2022-10-20", close: 131.529999},
    {date: "2022-10-21", close: 130.009995},
    {date: "2022-10-24", close: 129.720001},
    {date: "2022-10-25", close: 137.509995},
    {date: "2022-10-26", close: 129.820007},
    {date: "2022-10-27", close: 97.940002},
    {date: "2022-10-28", close: 99.199997},
    {date: "2022-10-31", close: 93.160004},
    {date: "2022-11-01", close: 95.199997},
    {date: "2022-11-02", close: 90.540001},
    {date: "2022-11-03", close: 88.910004},
    {date: "2022-11-04", close: 90.790001},
    {date: "2022-11-07", close: 96.720001},
    {date: "2022-11-08", close: 96.470001},
    {date: "2022-11-09", close: 101.470001},
    {date: "2022-11-10", close: 111.870003},
    {date: "2022-11-11", close: 113.019997},
    {date: "2022-11-14", close: 114.220001},
    {date: "2022-11-15", close: 117.080002},
    {date: "2022-11-16", close: 113.230003},
    {date: "2022-11-17", close: 111.449997},
    {date: "2022-11-18", close: 112.050003},
    {date: "2022-11-21", close: 109.860001},
    {date: "2022-11-22", close: 111.440002},
    {date: "2022-11-23", close: 112.239998},
    {date: "2022-11-25", close: 111.410004},
    {date: "2022-11-28", close: 108.779999},
    {date: "2022-11-29", close: 109.459999},
    {date: "2022-11-30", close: 118.099998},
    {date: "2022-12-01", close: 120.440002},
    {date: "2022-12-02", close: 123.489998},
    {date: "2022-12-05", close: 122.430000},
    {date: "2022-12-06", close: 114.120003},
    {date: "2022-12-07", close: 113.930000},
    {date: "2022-12-08", close: 115.330002},
    {date: "2022-12-09", close: 115.900002},
    {date: "2022-12-12", close: 114.709999},
    {date: "2022-12-13", close: 120.150002},
    {date: "2022-12-14", close: 121.589996},
    {date: "2022-12-15", close: 116.150002},
    {date: "2022-12-16", close: 119.430000},
    {date: "2022-12-19", close: 114.480003},
    {date: "2022-12-20", close: 117.089996},
    {date: "2022-12-21", close: 119.760002},
    {date: "2022-12-22", close: 117.120003},
    {date: "2022-12-23", close: 118.040001},
    {date: "2022-12-27", close: 116.879997},
    {date: "2022-12-28", close: 115.620003},
    {date: "2022-12-29", close: 120.260002},
    {date: "2022-12-30", close: 120.339996},
    {date: "2023-01-03", close: 124.739998},
    {date: "2023-01-04", close: 127.370003},
    {date: "2023-01-05", close: 126.940002},
    {date: "2023-01-06", close: 130.020004},
    {date: "2023-01-09", close: 129.470001},
    {date: "2023-01-10", close: 132.990005},
    {date: "2023-01-11", close: 132.889999},
    {date: "2023-01-12", close: 136.710007},
    {date: "2023-01-13", close: 136.979996},
    {date: "2023-01-17", close: 135.360001},
    {date: "2023-01-18", close: 133.020004},
    {date: "2023-01-19", close: 136.149994},
    {date: "2023-01-20", close: 139.369995},
    {date: "2023-01-23", close: 143.270004},
    {date: "2023-01-24", close: 143.139999}
]