export interface DefaultData {
    date: string;
    data: number;
}

export const myDefaultData: DefaultData[] = [
    {date: "2022-01-01",data: 10.0},
    {date: "2022-01-02",data: 9.0},
    {date: "2022-01-03",data: 8.0},
    {date: "2022-01-04",data: 7.0},
    {date: "2022-01-05",data: 6.0},
    {date: "2022-01-06",data: 5.0},
    {date: "2022-01-07",data: 4.0},
    {date: "2022-01-08",data: 3.0},
    {date: "2022-01-09",data: 2.0},
    {date: "2022-01-10",data: 1.0},
]