import {Group} from '@visx/group';
import {Bar} from '@visx/shape';
import {AxisScale} from '@visx/axis';
import {getX, getY} from "../../utils/GetData";
import React, {forwardRef, useMemo, useRef, useState} from "react";
import {ScaleBand} from "d3-scale";
import moment from "moment";
import {IndexFilter} from "../interfaces/IndexFilter";
import {IndexedData} from "../interfaces/IndexedData";
import {MockData} from "../interfaces/MockData";

export interface VisxBarGraphProps {
    data: MockData[],
    width: number,
    height: number,
    xScale: ScaleBand<any>,
    yScale: AxisScale<number>,
    color: string,
    filter?: IndexFilter
}

// helper function for bar calculation
const compose = (scale: any, accessor: any) => (data: any) => scale(accessor(data));

export const VisxBarGraph = forwardRef<SVGRectElement, VisxBarGraphProps>((props, forwardRef) => {
    const itemsRef = useRef<any[]>([]);
    const [sortedData, setSortedData] = useState<IndexedData[]>([]);
    const minX = props.filter?.filterStart ?? 0
    const maxX = props.filter?.filterEnd ?? props.data.length - 1
    const xPoint = compose(props.xScale, getX);
    const yPoint = compose(props.yScale, getY);
    let focusBarIndex: number;

    useMemo(() => {
        setSortedData(props.data
            .map((oldData, index): IndexedData => {
                return {value: oldData.value, index: index}
            })
            .slice(minX, maxX + 1)
            .sort((a, b) => a.value > b.value ? 1 : -1))
    }, [minX, maxX, props.data])

    const navigateChartKeyboardNavigation = (keyboardEvent: React.KeyboardEvent<SVGRectElement>) => {
        let sortedDataIndex: number;
        switch (keyboardEvent.key) {
            case 'ArrowRight':
                if (focusBarIndex === undefined) focusBarIndex = minX
                else if (keyboardEvent.shiftKey) focusBarIndex = maxX
                else if (focusBarIndex < maxX) focusBarIndex++
                itemsRef.current[focusBarIndex]?.focus();
                break;
            case 'ArrowLeft':
                if (focusBarIndex === undefined) focusBarIndex = maxX
                else if (keyboardEvent.shiftKey) focusBarIndex = minX
                else if (focusBarIndex > minX) focusBarIndex--
                itemsRef.current[focusBarIndex]?.focus();
                break;
            case 'ArrowDown':
                keyboardEvent.preventDefault();
                if (focusBarIndex === undefined) focusBarIndex = sortedData[sortedData.length - 1].index
                else if (keyboardEvent.shiftKey) focusBarIndex = sortedData[0].index
                else {
                    sortedDataIndex = sortedData.findIndex((data) => data.index === focusBarIndex)
                    if (sortedDataIndex > 0) sortedDataIndex--
                    focusBarIndex = sortedData[sortedDataIndex].index;
                }
                itemsRef.current[focusBarIndex]?.focus();
                break;
            case 'ArrowUp':
                keyboardEvent.preventDefault();
                if (focusBarIndex === undefined) focusBarIndex = sortedData[0].index
                else if (keyboardEvent.shiftKey) focusBarIndex = sortedData[sortedData.length - 1].index
                else {
                    sortedDataIndex = sortedData.findIndex((data) => data.index === focusBarIndex)
                    if (sortedDataIndex < sortedData.length - 1) sortedDataIndex++
                    focusBarIndex = sortedData[sortedDataIndex].index;
                }
                itemsRef.current[focusBarIndex]?.focus();
                break;
            default:
                break;
        }
    }

    return (
        <Group
            aria-label={`${props.data[0].category} from ${moment(props.data[minX]?.label).format("MMM Do YYYY")} to ${moment(props.data[maxX]?.label).format("MMM Do YYYY")}`}
            aria-roledescription="filter window"
            role={"graphics-object"}
            tabIndex={0}
            onKeyDown={navigateChartKeyboardNavigation}
            innerRef={forwardRef}
        >
            {props.data.map((d, i) => {
                const barHeight = props.height - yPoint(d);
                return (
                    <Bar
                        key={`${i}-bar`}
                        aria-label={`${moment(d.label).format("MMM Do YYYY")} with ${d.value} ${d.unit}`}
                        aria-roledescription="bar"
                        role={"graphics-symbol"}
                        aria-hidden={i < minX || i > maxX}
                        tabIndex={-1}
                        innerRef={reference => itemsRef.current[i] = reference}
                        x={xPoint(d)}
                        y={props.height - barHeight}
                        height={barHeight}
                        width={props.xScale.bandwidth()}
                        fill={i >= minX && i <= maxX ? props.color : props.color + "20"}
                    />
                );
            })}
        </Group>
    );
});