import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface DatePickerContainerProps {
    minDate: Date
    maxDate: Date
    startDate: Date
    endDate: Date
    setStartDate: (value: Date) => void
    setEndDate: (value: Date) => void
}

export const DatePickerContainer = (props: DatePickerContainerProps) => {
    const onChange = (dates: [Date, Date]) => {
        const [start, end] = dates;
        props.setStartDate(start);
        props.setEndDate(end);
    };
    return (
        <DatePicker
            selected={props.startDate}
            onChange={onChange}
            startDate={props.startDate}
            endDate={props.endDate}
            minDate={props.minDate}
            maxDate={props.maxDate}
            selectsRange
        />
    );
};