import {Route, Routes} from "react-router-dom";
import {Paths} from "./utils/Paths";
import {DashBoard} from "./components/dashboard/DashBoard";
import {DashBoardOne} from "./components/dashboard/DashBoardOne";
import {DashBoardTwo} from "./components/dashboard/DashBoardTwo";
import {DashBoardThree} from "./components/dashboard/DashBoardThree";
import {MobileDashBoardOne} from "./components/mobile/MobileDashBoardOne";
import {MobileDashBoardTwo} from "./components/mobile/MobileDashBoardTwo";
import {MobileDashBoardThree} from "./components/mobile/MobileDashBoardThree";

function App() {
    return (
        <>
            <Routes>
                <Route path={Paths.DEFAULT} element={<DashBoard/>}/>
                <Route path={Paths.TEST1} element={<DashBoardOne/>}/>
                <Route path={Paths.TEST2} element={<DashBoardTwo/>}/>
                <Route path={Paths.TEST3} element={<DashBoardThree/>}/>
                <Route path={Paths.MOBILE1} element={<MobileDashBoardOne/>}/>
                <Route path={Paths.MOBILE2} element={<MobileDashBoardTwo/>}/>
                <Route path={Paths.MOBILE3} element={<MobileDashBoardThree/>}/>
            </Routes>
        </>
    );
}

export default App;
