import {ScaleBand} from "d3-scale";
import React, {useMemo, useRef, useState} from "react";
import {savitzkyGolayFilter} from "../../utils/SavitzkyGolayFilter";
import {AxisScale} from "@visx/axis";
import {AudioPlayer, SonificationPlayer} from "../../audio/AudioPlayer";
import {Group} from "@visx/group";
import {useHover} from "@use-gesture/react";
import {MockData} from "../interfaces/MockData";

interface MobileTactileLineGraphProps {
    data: MockData[],
    savitzkyFilterWindowSize: number,
    xScale: ScaleBand<any>,
    yScale: AxisScale<number>,
    color: string,
    oscType: OscillatorType
    pointRadius: number
}

const getX = (d: MockData) => d.label;
const getY = (d: MockData) => d.value;
export const MobileTactileLineGraph = (props: MobileTactileLineGraphProps) => {
    const [touch, setTouch] = useState(0)

    const audioPlayerRef = useRef<SonificationPlayer>(null);
    const smoothedData: MockData[] = useMemo(() => {
            return savitzkyGolayFilter(props.data, props.savitzkyFilterWindowSize)
        }, [props.data, props.savitzkyFilterWindowSize]
    );

    const vibratePhone = (index: number) => {
        setTouch(touch + 1)
        if ("vibrate" in navigator) {
            console.log("Vibrating")
            navigator.vibrate(100);
            audioPlayerRef.current?.playNoteAt(index)
        } else {
            console.log("Not supported")
        }
    }

    return (
        <Group>
            {smoothedData.filter((d, i) => i % Math.round(props.data.length / props.savitzkyFilterWindowSize / 2) === 0).map((d, i) => {
                return (
                    <circle
                        onClick={() => vibratePhone(i)}
                        style={{touchAction: "none"}}
                        key={`tact-${i}`}
                        cx={props.xScale(getX(d))}
                        cy={props.yScale(getY(d))}
                        r={props.pointRadius}
                        fill={props.color}
                    />)
            })
            }
            <AudioPlayer speed={1} oscType={props.oscType} data={smoothedData.map(d => d.value)} ref={audioPlayerRef}/>
        </Group>
    )
}