import React, {forwardRef, useImperativeHandle, useMemo, useRef, useState} from "react";
import {LinePath} from "@visx/shape";
import {ScaleBand} from "d3-scale";
import {AxisScale} from "@visx/axis";
import {getX, getY} from "../../utils/GetData";
import {savitzkyGolayFilter} from "../../utils/SavitzkyGolayFilter";
import {AudioPlayer, SonificationPlayer} from "../../audio/AudioPlayer";
import {MockData} from "../interfaces/MockData";
import {IndexFilter} from "../interfaces/IndexFilter";

export interface AudioLineGraphProps {
    data: MockData[],
    xScale: ScaleBand<Date>,
    yScale: AxisScale<number>,
    savitzkyFilterWindowSize: number
    speed: number
    brushFilter?: IndexFilter,
    highlightPoints?: number[],
    setAudioIndex?: (index: number) => void
    color: string,
    oscType: OscillatorType
}

export const AudioLineGraph = forwardRef<SonificationPlayer, AudioLineGraphProps>((props, ref) => {
    const [pointIndex, _setPointIndex] = useState<number>(0)
    const audioPlayerRef = useRef<SonificationPlayer>(null);
    const smoothedData = useMemo(() => savitzkyGolayFilter(props.data, props.savitzkyFilterWindowSize), [props.data, props.savitzkyFilterWindowSize]);

    const setPointIndex = (index: number) => {
        _setPointIndex(index)
        if (props.setAudioIndex) {
            props.setAudioIndex(index)
        }
    }

    useImperativeHandle(ref, () => ({
        playSonification() {
            if (!audioPlayerRef.current) return new Promise<void>(resolve => resolve())
            return audioPlayerRef.current.playSonification();
        },
        stopSonification(): Promise<void> | undefined {
            return audioPlayerRef.current?.stopSonification()
        },
        playNoteAt(noteIndex: number): Promise<boolean> {
            if (!audioPlayerRef.current) return new Promise<boolean>(resolve => resolve(false))
            return audioPlayerRef.current.playNoteAt(noteIndex)
        }
    }));

    return (
        <>
            <LinePath
                stroke={props.color}
                strokeOpacity={0.8}
                strokeDasharray={5}
                strokeWidth={3}
                data={smoothedData}
                x={(d) => props.xScale(getX(d)) ?? 0}
                y={(d) => props.yScale(getY(d)) ?? 0}
            />
            <circle
                cx={props.xScale(smoothedData[pointIndex].label)}
                cy={props.yScale(smoothedData[pointIndex].value)}
                r={1}
                fill="white"
                stroke={"#000"}
                strokeWidth={6}
            />
            <AudioPlayer speed={props.speed}
                         brushFilter={props.brushFilter}
                         setAudioIndex={setPointIndex}
                         data={smoothedData.map(d => d.value)}
                         highlightPoints={props.highlightPoints}
                         oscType={props.oscType}
                         ref={audioPlayerRef}/>
        </>
    )
})